<template>
  <div class="table-container">
    <br />
    <table class="table">
      <!-- HEADER -->
      <thead>
      </thead>
      <tbody>
        <!-- CONTENT -->
         <template v-for="row in myTable">
          <tr v-if="row.showHeader" :key="'headrow' + row.id" style="border-right:1px solid gray; border-top:1px solid gray;">
            <th style="" scope="col" class="documentsTh"></th>
            <th
              scope="col"
              class="myCol documentsTh rotated-th"
              v-for="col in row.content"
              v-bind:key="'headcol' + col.id"
              style="text-align: center; border-right:1px solid lightgray;"
            >
              {{ col.numberString }} &nbsp;&nbsp;-&nbsp;&nbsp; {{ col.name }}
            </th>
          </tr>
          <tr class="myRow" :key="'contentrow' + row.id" style="border-right:1px solid gray;">
            <th style=" padding:0px 15px; line-height: 30px; text-align:right" scope="row" class="documentsTh">
              {{ row.name }} &nbsp;&nbsp; {{ row.number }}
            </th>
            <td
              v-for="col in row.content"
              class="myCol"
              :key="'contentcol' + col.id"
              style="text-align: center; padding:0px;border-right:1px solid lightgray;"
            >
              <checkbox :value="col.selected" @Update="togglePivot(row.id, col.id)"/>
            </td>
          </tr>
         </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import button from "../uicomponents/button.vue";
export default {
  components: { button },
  name: "MappingEmploymentModelWageTypes",
  data() {
    return {
      loading: true,
      saves: false,
      row_employmentModels: [],
      col_wageTypes: [],
      pivots: [],
      myTable: [],
    };
  },
  created: function () {
    if (this.$store.state.jwt != undefined && this.$store.state.companyId != undefined) {
      this.refresh();
    }
  },
  mounted: function () {},
  computed: {
    ...mapGetters(["companyId"]),
  },
  watch: {
    companyId: function (val, oldVal) {
      this.refresh();
    },
  },
  // methods that implement data logic.
  methods: {
    refresh: function (force = false) {
      let view = this;
      view.loading = true;
      view.loadModels();
    },
    loadModels: function () {
      this.items = [];
      let view = this;
      let params = { companyId: view.$store.state.companyId };
      view.$helpers
        .GetCall("/api/sec/individualWageTypes", params)
        .then((response) => {
          let data = response.data.data;
          let wageTypes = [];
          for (let i = 0; i < data.length; i++) {
            let wageType = {
              id: data[i].id,
              numberString: data[i].numberString,
              name: data[i].name,
              sorting: data[i].sorting,
              color: data[i].color,
            };
            wageTypes.push(wageType);
          }
          view.col_wageTypes = wageTypes;

          view.$helpers
            .GetCall("/api/sec/employmentModels", params)
            .then((response) => {
              let data = response.data.data;
              let employmentModels = [];
              for (let i = 0; i < data.length; i++) {
                let employmentModel = {
                  id: data[i].id,
                  number: data[i].number,
                  name: data[i].name,
                  sorting: data[i].sorting,
                  color: data[i].color,
                };
                if(data[i].isActive) {
                  employmentModels.push(employmentModel);
                }
              }
              view.row_employmentModels = employmentModels;

              view.$helpers
                .GetCall("/api/sec/pivot/employmentModels_individualWageTypes", params)
                .then((response) => {
                  view.pivots = {};
                  for (let i = 0; i < response.data.length; i++) {
                    if (view.pivots[response.data[i].employmentModelsId] == null) {
                      view.pivots[response.data[i].employmentModelsId] = {};
                    }
                    view.pivots[response.data[i].employmentModelsId][
                      response.data[i].individualWageTypesId
                    ] = response.data[i].selected;
                  }

                  view.myTable = {};
                  let showHeader = true;
                  for (let j = 0; j < view.row_employmentModels.length; j++) {
                    let row = {};
                    for (let i = 0; i < view.col_wageTypes.length; i++) {
                      if (
                        view.pivots[view.row_employmentModels[j].id] &&
                        view.pivots[view.row_employmentModels[j].id][
                          view.col_wageTypes[i].id
                        ]
                      ) {
                        row[view.col_wageTypes[i].id] = {
                          id: view.col_wageTypes[i].id,
                          name: view.col_wageTypes[i].name,
                          numberString: view.col_wageTypes[i].numberString,
                          selected:
                            view.pivots[view.row_employmentModels[j].id][
                              view.col_wageTypes[i].id
                            ],
                        };
                      } else {
                        row[view.col_wageTypes[i].id] = {
                          id: view.col_wageTypes[i].id,
                          name: view.col_wageTypes[i].name,
                          numberString: view.col_wageTypes[i].numberString,
                          selected: 0,
                        };
                      }
                    }
                    view.myTable[view.row_employmentModels[j].id] = {
                      id: view.row_employmentModels[j].id,
                      number: view.row_employmentModels[j].number,
                      numberString: view.row_employmentModels[j].numberString,
                      line: j,
                      name: view.row_employmentModels[j].name,
                      content: row,
                      showHeader: showHeader
                    };
                    if (showHeader) {
                      showHeader = false;
                    }else if(j % 20 == 0){
                      showHeader = true;
                    }
                  }
                })
                .catch((e) => {
                  view.loading = false;
                  console.log(e);
                });
            })
            .catch((e) => {
              view.loading = false;
              console.log(e);
            });
        })
        .catch((e) => {
          view.loading = false;
          console.log(e);
        });
    },
    togglePivot: function (rowid, colid) {
      let view = this;

      let url = "/api/sec/pivot/employmentModels_individualWageTypes";
      url = this.$helpers.appendParam(
        "companyId",
        this.$store.state.companyId,
        url,
        "/api/sec/pivot/employmentModels_individualWageTypes"
      );
      let payload = {
        companyId: this.$store.state.companyId,
        employmentModelsId: rowid,
        individualWageTypesId: colid,
      };
      this.axios({
        method: "post",
        headers: {
          AUTHORIZATION: "Bearer " + view.$store.state.jwt,
        },
        url: url,
        data: payload,
      })
        .then((response) => {
          console.log(rowid);
          console.log(colid);
          view.myTable[rowid].content[colid].selected == 1
            ? (view.myTable[rowid].content[colid].selected = 0)
            : (view.myTable[rowid].content[colid].selected = 1);
          view.$forceUpdate();
          view.$helpers.success("Gespeichert", "Erfolgreich geändert");
        })
        .catch(function (error) {
          console.log(error);
          view.$helpers.danger("Error", "Speichern nicht möglich");
        });
    },
  },
};
</script>

<!-- Add "scoped" attriemployeete to limit CSS to this component only -->
<style scoped>
.myRow {
  height: 25px;
}
.myCol {
  width: 25px;
  background-color: #fff !important;
}
.myCol:hover {
  background-color: #eee !important;
}
.rotated-th {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  /* Additional styles for better presentation (optional) */
  text-align: left;
  /* Adjust font size if needed */
  font-size: 12px;
}
.table-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content; /* Optional: Set a maximum width if needed */
  margin: 0 auto; /* Optional: Add margin for better centering */
}
</style>
