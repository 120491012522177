<template>
  <div class="main-view-padding has-header">
    <div class="header main" v-header-position-lock>
      <div class="align-left"></div>
      <div class="align-middle">
        <headerbuttonstack :state="headerButtons" />
      </div>
      <div class="align-right"></div>
    </div>
    <div
      style="
        font-size: 32px;
        text-align: left;
        color: #e51550;
        border-bottom: 1px solid #ccc;
        padding: 5px;
        font-weight: 400;
        margin-bottom: 25px;
      "
    >
      Individuelle Lohnarten Konfigurator
    </div>

    <div style="max-width:700px; margin: 0 auto;">
      <table class="table">
        <tr>
          <th>Nummer</th>
          <th>Name</th>
          <th>Farbe</th>
          <th>Lohnart Details</th>
          <th>Bearbeiten</th>
        </tr>
        <tr v-for="x in models" v-bind:key="x.id" style="margin:0px;">
          <td style="padding:1px; font-size:14px;">{{ x.numberString }}</td>
          <td style="padding:1px; font-size:14px;">{{ x.name }}</td>
          <td style="display: flex; align-items: center; align-content: center; padding:4px 0px 0px 0px;">
            <div
              style="
                width: 15px;
                height: 15px;
                background-color: #f00;
                text-align: center;
                cursor: pointer;
                margin: 0 auto;
              "
              v-bind:style="{ 'background-color': x.color }"
              v-on:click="toggleColorPicker(x)"
            ></div>
          </td>
          <td style="padding:1px; font-size:14px;">
            <i
              class="fas fa-circle-exclamation"
              v-if="!x.details"
              v-tooltip="
                'Für diese Lohnart wurden noch keine Berechnungsdetails angelegt.'
              "
              style="color: var(--ml)"
              >&nbsp;</i
            >
            <i class="fas fa-edit" v-on:click="openEditEntityDetails(x)"></i>
          </td>
          <td style="padding:1px; font-size:14px;">
            <i class="fas fa-edit" v-on:click="openEditEntity(x)"></i>
          </td>
        </tr>
      </table>
    </div>
    <br />
    <hr />
    <h4>Export-Mapping Tabelle</h4>
    <MappingEmploymentModelWageTypes ref="child"></MappingEmploymentModelWageTypes>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import button from "../uicomponents/button.vue";
import MappingEmploymentModelWageTypes from "./MappingEmploymentModelWageTypes.vue";

export default {
  components: { button, MappingEmploymentModelWageTypes },
  name: "IndividualWageTypes",
  data() {
    return {
      loading: true,
      saves: false,
      models: [],
      mdWagetypes: [],
      stores: [],
    };
  },
  created: function () {
    if (this.$store.state.jwt != undefined && this.$store.state.companyId != undefined) {
      this.refresh();
    }
  },
  mounted: function () {},
  computed: {
    ...mapGetters(["companyId"]),
    headerButtons() {
      let view = this;

      let state = {
        buttons: [],
      };
      state.buttons.push({
        type: "block",
        icon: "fa-refresh",
        click: () => {
          view.refresh();
        },
        tooltip: "Daten neu laden",
      });
      state.buttons.push({
        type: "block",
        icon: "fa-plus",
        click: ($event) => {
          view.openNewEmploymentModel($event);
        },
        tooltip: "Neu anlegen",
      });
      return state;
    },
  },
  watch: {
    companyId: function (val, oldVal) {
      this.refresh();
    },
  },
  // methods that implement data logic.
  methods: {
    refresh: function (force = false) {
      let view = this;
      view.loading = true;

      view.$helpers.GetStoresSettings(true).then((response) => {
        view.stores = response.data;
      })

      view.loadWageTypes();
      view.loadModels();
    },
    loadModels: function () {
      let view = this;
      let params = { companyId: view.$store.state.companyId };

      view.$helpers
        .GetCall("/api/sec/individualWageTypes", params)
        .then((response) => {
          let data = response.data.data;

          // Sorting the data by numberString before further processing
          data.sort((a, b) => {
            // Handle cases where numberString might be undefined or null
            const numA = a.numberString ? parseFloat(a.numberString) : Infinity;
            const numB = b.numberString ? parseFloat(b.numberString) : Infinity;
            return numA - numB;
          });

          let tmp = [];
          // Looping through sorted data and creating objects
          for (let i = 0; i < data.length; i++) {
            let item = {
              id: data[i].id,
              numberString: data[i].numberString,
              name: data[i].name,
              sorting: data[i].sorting,
              color: data[i].color,
              details: data[i].details,
            };
            tmp.push(item);
          }
          view.models = tmp;
        })
        .catch((e) => {
          view.loading = false;
          console.log(e);
        });
    },
    loadWageTypes: function () {
      let view = this;
      let url = "/api/sec/wagetypes";
      let params = { companyId: view.$store.state.companyId };
      view.$helpers
      .GetCall(url, params)
        .then((response) => {
          let data = response.data;
          let tmp = [];
          for (let i = 0; i < data.length; i++) {
            let item = {
              id: data[i].id,
              key: data[i].key,
              name: data[i].name
            };
            tmp.push(item);
          }
          view.mdWagetypes = tmp.slice();
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    openNewEmploymentModel(event) {
      let view = this;
      let dataEntries = [
        {
          label: "Schlüssel: ",
          key: "numberString",
          hidden: false,
          type: "numeric",
          value: 0,
          min: 0,
          max: 65535,
          styles: { width: "350px" },
        },
        {
          label: "Bezeichnung: ",
          key: "name",
          hidden: false,
          type: "text",
          value: "",
          length: 128,
          styles: { width: "350px" },
        },
        //{label: 'Sortierungs Priorität', key: 'priority', hidden: false, type: 'slider', value: 1, slider: {value: 1, min: 1, max: 100}},
        { flexDivider: true, styles: { "margin-bottom": "23px" } },
      ];
      this.$helpers.OpenPopup({
        elRelative: event.currentTarget,
        type: "datafill",
        pointerDirection: 0,
        componentState: {
          entries: dataEntries,
          selectCallback: function (newFIBU) {
            view.createFinancialAccountingGroup(newFIBU);
          },
          errorCheck: {
            numberString: (entries) => {
              if (
                entries.hasOwnProperty("numberString") &&
                view.models.find((f) => f.numberString === entries.numberString)
              )
                return {
                  key: "numberString",
                  type: "error",
                  text: "Wird bereits verwendet",
                };
              else return null;
            },
            name: (entries) => {
              if (
                entries.hasOwnProperty("name") &&
                view.models.find((f) => f.name === entries.name)
              )
                return {
                  key: "name",
                  type: "error",
                  text: "Wird bereits verwendet",
                };
              else return null;
            },
          },
        },
        header: "Neues Beschäftigungsmodell",
        remainAfterSelect: false,
        expanded: true,
      });
    },
    openEditEntity(entity) {
      let view = this;

      let dataEntries = [
        {
          label: "id: ",
          key: "id",
          hidden: true,
          type: "text",
          value: entity.id,
          length: 128,
          styles: { width: "350px" },
        },
        {
          label: "Nummer: ",
          key: "numberString",
          hidden: false,
          type: "text",
          value: entity.numberString,
          length: 128,
          styles: { width: "350px" },
        },
        {
          label: "Name: ",
          key: "name",
          hidden: false,
          type: "text",
          value: entity.name,
          length: 128,
          styles: { width: "350px" },
        },
        //{label: 'Sortierungs Priorität', key: 'priority', hidden: false, type: 'slider', value: fibu.sorting, slider: {value: fibu.sorting, min: 1, max: 100}},
        { flexDivider: true, styles: { "margin-bottom": "23px" } },
      ];
      this.$helpers.OpenPopup({
        elRelative: null,
        type: "datafill",
        pointerDirection: 0,
        componentState: {
          entries: dataEntries,
          selectCallback: function (eEntity) {
            view.editEntity(eEntity);
          },
          errorCheck: {
            numberString: (entries) => {
              if (
                entries.hasOwnProperty("numberString") &&
                view.models.find(
                  (f) =>
                    f.numberString !== entity.numberString &&
                    f.numberString === entries.numberString
                )
              )
                return {
                  key: "numberString",
                  type: "error",
                  text: "Wird bereits verwendet",
                };
              else return null;
            },
            name: (entries) => {
              if (
                entries.hasOwnProperty("name") &&
                view.models.find((f) => f.name !== entity.name && f.name === entries.name)
              )
                return {
                  key: "name",
                  type: "error",
                  text: "Wird bereits verwendet",
                };
              else return null;
            },
          },
        },
        header: "Beschäftigungsmodell bearbeiten",
        remainAfterSelect: false,
        centered: true,
        expanded: true,
      });
    },
    openEditEntityDetails(entity) {
      console.log(entity);
      if(entity.id == 18){
        let dataEntries = [
          {
            label: "",
            key: "numberString",
            hidden: false,
            type: "output",
            value: "Für diese Lohnart wird immer der Wert 7,8 Stunden übertragen.",
          }
        ];
        this.$helpers.OpenPopup({
          elRelative: null,
          type: "datafill",
          pointerDirection: 0,
          componentState: {
            positiveButtonLabel: "Popup schließen",
            entries: dataEntries,
            selectCallback: function (eFibu) {
            },
          },
          header: "Information",
          remainAfterSelect: false,
          centered: true,
          expanded: true,
        });
        return;
      }
      if(entity.id == 155 || entity.id == 156){
        let dataEntries = [
          {
            label: "",
            key: "numberString",
            hidden: false,
            type: "output",
            value: "Für diese Lohnart wird immer der Wert 1 Stunde übertragen.",
          }
        ];
        this.$helpers.OpenPopup({
          elRelative: null,
          type: "datafill",
          pointerDirection: 0,
          componentState: {
            positiveButtonLabel: "Popup schließen",
            entries: dataEntries,
            selectCallback: function (eFibu) {
            },
          },
          header: "Information",
          remainAfterSelect: false,
          centered: true,
          expanded: true,
        });
        return;
      }

      let view = this;

      let bonusTypes = [
        {value: 0, label: 'Ohne Bonus'},
        {value: 1, label: 'Mit Bonus'},
        {value: 2, label: 'Nur Bonus'},
      ]
      let bonusModeItems = [
        //{value: 1, label: 'Stunden Bonus bis zur Grenze, Pauschale ab Grenze'},
        { value: 2, label: "Pauschale"}, // sobald Zeitraum beginnt" },
        { value: 3, label: "Stunden Bonus"}, // sobald Zeitraum beginnt" },
        //{value: 4, label: 'Pauschale ab Grenze'},
        //{value: 5, label: 'Stunden Bonus ab Grenze'},
      ];
      let initialMode = {
        value: 3,
        label: "Stunden Bonus sobald Zeitraum beginnt",
      };
      let workTypes = [
        { value: 0, label: "Arbeitszeit" },
        { value: 1, label: "Abwesenheit" },
        { value: 2, label: "Andere Kosten" },
        { value: 3, label: "Bonuszahlung" },
        { value: 4, label: "Monatssummen" },
      ];
      let holidayTypes = [
        {value: 0, label: 'Inklusive Feiertage'},
        {value: 1, label: 'Nur Feiertage'},
        //{value: 2, label: 'Ohne Feiertage'},
      ];
      let mdEmployeeAvailabilityTypes = [
        { value: 1, label: "Urlaub" },
        { value: 2, label: "Krankheit" },
        { value: 3, label: "Feiertags Ausgleich" },
        { value: 4, label: "Sonstiges" },
        // { value: 5, label: "Kein Vertrag" },
        { value: 6, label: "Pflegeurlaub" },
        { value: 7, label: "Ausbildung" },
        { value: 8, label: "Lohnfortzahlung" },
      ];
      let mdEmployeeAvailabilitySubTypes = [
        // { value: -1, parentType: 2, label: "Kein Detail" }, // -> deprecated, changed to 22 and 23
        { value: 1, parentType: 8, label: "Sonstiges" },
        { value: 2, parentType: 8, label: "Hochzeit" },
        { value: 3, parentType: 8, label: "Todesfall" },
        { value: 4, parentType: 8, label: "Beerdigung" },
        { value: 5, parentType: 8, label: "Geburt eines Kindes" },
        { value: 6, parentType: 8, label: "Umzug" },
        { value: 7, parentType: 8, label: "Präsenzdienst" },
        { value: 8, parentType: 8, label: "Arbeitsunfall" },
        { value: 9, parentType: 8, label: "Arztbesuch" },
        { value: 10, parentType: 8, label: "Vorladung zu Behörde" },
        { value: 11, parentType: 8, label: "Quarantäne" },
        { value: 12, parentType: 4, label: "Zeitausgleich" },
        { value: 13, parentType: 2, label: "Krankenstand ohne Arztbestätigung" },
        { value: 14, parentType: 8, label: "Geburtstag" },
        { value: 15, parentType: 4, label: "Unbegründete Abwesenheit" },
        { value: 16, parentType: 4, label: "Unbezahlter Urlaub" },
        { value: 17, parentType: 4, label: "Freistellung" },
        { value: 18, parentType: 8, label: "Bürotag" },
        { value: 19, parentType: 8, label: "Home Office" },
        { value: 20, parentType: 8, label: "Feiertagsentgelt" },
        { value: 21, parentType: 2, label: "Staatliches Krankengeld" },
        { value: 22, parentType: 2, label: "Regulärer Krankenstand" },
        { value: 23, parentType: 4, label: "Kein Detail" },
      ];
      let mdStoresEnabled = [];
      for (let i = 0; i < view.stores.length; i++) {
        mdStoresEnabled.push({ value: view.stores[i].id, label: view.stores[i].name });
      }
      let extraCostTypes = [
        { value: 1, label: "Personalessen" },
        { value: 2, label: "Garderobe" },
        { value: 10, label: "Vorschuss" },
        { value: 11, label: "Miete" },
        { value: 12, label: "Bussgeld" },
        { value: 14, label: "Arbeitsschuhe" },
        { value: 15, label: "Schlüssel" },
        { value: 16, label: "Lernmaterial" },
        { value: 17, label: "Bearbeitungsgebühr" },
        { value: 20, label: "Abzug Kleidung" },
        { value: 22, label: "Kaution Kleidung" },
        { value: 23, label: "Vertragsstrafe" },
        { value: 0, label: "Sonstiges" },
      ];
      let bonusExpenseTypes = [
        { value: 2, label: "Garderobe" },
        { value: 3, label: "Nachtarbeit" },
        { value: 4, label: "Wochenendarbeit" },
        { value: 5, label: "Urlaubsentgelt" },
        { value: 6, label: "Rufbereitschaft" },
        { value: 7, label: "Weihnachtsgeld" },
        { value: 8, label: "Tarifzulage" },
        { value: 9, label: "Prämie" },
        { value: 10, label: "Vorschuss" },
        { value: 13, label: "Urlaubsabgeltung" },
        { value: 15, label: "Schlüssel" },
        { value: 18, label: "Management Bonus" },
        { value: 19, label: "Fahrtgeld" },
        { value: 21, label: "Crew Bonus" },
        { value: 22, label: "Kaution Kleidung" },
        { value: 0, label: "Sonstiges" },
      ];

      // fields from MD

      // let monthlyFinancialStatementFields = [
      //   { value: "workTimeRegular", label: "Arbeitszeit (Soll)" },
      //   { value: "prevMonthTime", label: "Übertrag Vormonat" },
      //   { value: "workTime", label: "Produktive Arbeitszeit" },
      //   { value: "unproductiveWorkTime", label: "Unproduktive Arbeitszeit" },
      //   { value: "sickWorkTime", label: "Arbeitszeit Krankenstand" },
      //   { value: "wageContinuationWorkTime", label: "Arbeitszeit Lohnfortzahlung" },
      //   { value: "educationWorkTime", label: "Arbeitszeit Ausbildung" },
      //   { value: "nursingVacationWorkTime", label: "Arbeitszeit Pflegeurlaub" },
      //   { value: "vacationWorkTime", label: "Arbeitszeit Urlaub" },
      //   { value: "miscWorkTime", label: "Arbeitszeit Sonstiges" },
      //   { value: "paidWorkTime", label: "Arbeitszeit Modifizierung" },
      //
      //   { value: "workTimeBalance", label: "Arbeitszeit Saldo" },
      //   { value: "currentMonthWorkTimeBalance", label: "Arbeitszeit Saldo ohne Vormonat" },
      //   { value: "unmodifiedWorkTimeBalance", label: "Unmodifiziertes Arbeitszeit Saldo" },
      //   { value: "workTimeBalanceAccPeriod", label: "Arbeitszeit Saldo (letzte DR)" },
      //
      //   { value: "vacationTime", label: "Verbrauchter Urlaub" },
      //   { value: "paidVacationTime", label: "Urlaub Modifizierung" },
      //   { value: "vacationTimeBalance", label: "Urlaub Saldo" },
      //   { value: "unmodifiedVacationTimeBalance", label: "Unmodifiziertes Urlaub Saldo" },
      //   { value: "vacationTimeBalanceAccPeriod", label: "Urlaub Saldo (letzte DR)" },
      //
      //   { value: "holidayTime", label: "Feiertagszeit" },
      //   { value: "holidayAdjustmentWorkTime", label: "Feiertagausgleich" },
      //   { value: "paidHolidayTime", label: "Feiertagszeit Modifizierung" },
      //   { value: "holidayTimeBalance", label: "Feiertagszeit Saldo" },
      //   { value: "unmodifiedHolidayTimeBalance", label: "Unmodifiziertes Feiertagszeit Saldo" },
      //   { value: "holidayTimeBalanceAccPeriod", label: "Feiertagszeit Saldo (letzte DR)" },
      //
      //   { value: "nightWorkTime", label: "Nachtarbeit" },
      //   { value: "nightWorkCount", label: "Nachtarbeit Anzahl" },
      //   { value: "prevMonthNightWorkTime", label: "Nachtarbeit Vormonat" },
      //   { value: "paidNightWorkTime", label: "Nachtarbeit Modifizierung" },
      //   { value: "nightWorkTimeBalance", label: "Nachtarbeit Saldo" },
      //   { value: "unmodifiedNightWorkTimeBalance", label: "Unmodifiziertes Nachtarbeit Saldo" },
      //   { value: "nightWorkTimeBalanceAccPeriod", label: "Nachtarbeit Saldo (letzte DR)" },
      //
      //   { value: "overTime", label: "Überstunden" },
      //   { value: "prevMonthOverTime", label: "Überstunden Vormonat" },
      //   { value: "paidOverTime", label: "Überstunden Modifizierung" },
      //   { value: "overTimeBalance", label: "Überstunden Saldo" },
      //   { value: "unmodifiedOverTimeBalance", label: "Unmodifiziertes Überstunden Saldo" },
      //   { value: "overTimeBalanceAccPeriod", label: "Überstunden Saldo (letzte DR)" },
      //
      //   { value: "moreTime", label: "Mehrstunden" },
      //   { value: "prevMonthMoreTime", label: "Mehrstunden Vormonat" },
      //   { value: "paidMoreTime", label: "Mehrstunden Modifizierung" },
      //   { value: "moreTimeBalance", label: "Mehrstunden Saldo" },
      //   { value: "unmodifiedMoreTimeBalance", label: "Unmodifiziertes Mehrstunden Saldo" },
      //   { value: "moreTimeBalanceAccPeriod", label: "Mehrstunden Saldo (letzte DR)" },
      //
      //   { value: "bonusTime", label: "Bonusstunden" },
      //   { value: "prevMonthBonusTime", label: "Bonusstunden Vormonat" },
      //   { value: "paidBonusTime", label: "Bonusstunden Modifizierung" },
      //   { value: "bonusTimeBalance", label: "Bonusstunden Saldo" },
      //   { value: "unmodifiedBonusTimeBalance", label: "Unmodifiziertes Bonusstunden Saldo" },
      //   { value: "bonusTimeBalanceAccPeriod", label: "Bonusstunden Saldo (letzte DR)" },
      //
      //   { value: "comment", label: "Kommentar" },
      //
      //   { value: "fiftypercentpayout", label: "50% Zuschlag" },
      //   { value: "fiftypercentpayoutWithoutAccPeriod", label: "50% Zuschlag ohne DR" },
      //   { value: "twentyfivepercentpayout", label: "25% Zuschlag" },
      //   { value: "twentyfivepercentpayoutWithoutAccPeriod", label: "25% Zuschlag ohne DR" },
      //
      //   { value: "workDaysPlanned", label: "Arbeitstage (Soll)" },
      //   { value: "workDaysActual", label: "Arbeitstage (Ist)" },
      //
      //   { value: "sickDays", label: "Krankenstand Tage" },
      //   { value: "holidayAdjustmentDays", label: "Feiertagausgleich Tage" },
      //   { value: "wageContinuationDays", label: "Lohnfortzahlung Tage" },
      //   { value: "educationDays", label: "Ausbildung Tage" },
      //   { value: "nursingVacationDays", label: "Pflegeurlaub Tage" },
      //   { value: "miscDays", label: "Sonstige Tage" },
      //
      //   { value: "weekendWorkPlanned", label: "Wochenend Arbeit (Soll)" },
      //   { value: "weekendWorkActual", label: "Wochenend Arbeit (Ist)" },
      //   { value: "weekendWorkCountPlanned", label: "Wochenend Arbeit Anzahl (Soll)" },
      //   { value: "weekendWorkCountActual", label: "Wochenend Arbeit Anzahl (Ist)" },
      // ];

      // fields available on ML Export Page

      // let monthlyFinancialStatementFields = [
      //   { value: "paidWorkTimeOnlyPositive", label: "Ausbezahlte Arbeitszeit (nur +)" },
      //   { value: "paidOverTimeOnlyPositive", label: "Ausbez. zuschlagspfl. Überstunden (nur +)" },
      //   { value: "paidMoreTimeOnlyPositive", label: "Ausbezahlte Mehrstunden (nur +)" },
      //   { value: "paidNightWorkTimeOnlyPositive", label: "Ausbezahlte Nachtarbeit (nur +)" },
      //
      //   { value: "paidHolidayTimeOnlyPositive", label: "Ausbezahlte Feiertagsarbeit (nur +)" },
      //   { value: "paidBonusTimeOnlyPositive", label: "Ausbezahlte Bonusstunden (nur +)" },
      //
      //   { value: "vacationTimeBalance", label: "Rest Urlaub im Kalenderjahr" },
      //   { value: "holidayTimeBalance", label: "Feiertags Saldo" },
      //   { value: "workTimeBalance", label: "Arbeitszeit Saldo" },
      //   { value: "workTimeRegular", label: "Arbeitszeit Soll" },
      //   { value: "prevMonthTime", label: "Übertrag Vormonat" },
      //   { value: "workTime", label: "Produktive Arbeitszeit Ist" },
      //   { value: "unproductiveWorkTime", label: "Unproduktive Arbeitszeit Ist" },
      //   { value: "sickWorkTime", label: "Krankenstand (h)" },
      //   { value: "wageContinuationWorkTime", label: "Lohnfortzahlung (h)" },
      //   { value: "educationWorkTime", label: "Ausbildung (h)" },
      //   { value: "nursingVacationWorkTime", label: "Pflegeurlaub (h)" },
      //   { value: "vacationWorkTime", label: "Urlaub (h)" },
      //   { value: "miscWorkTime", label: "Sonstige Abwesenheiten (h)" },
      //   { value: "paidWorkTime", label: "Ausbezahlte Arbeitszeit" },
      //   { value: "accountingPeriodBalance", label: "Durchrechnungs-Übertrag" },
      //   { value: "vacationTime", label: "Urlaub (Tage)" },
      //   { value: "paidVacationTime", label: "Ausbezahlter Urlaub (Tage)" },
      //   { value: "holidayTime", label: "Feiertags Arbeitszeit" },
      //   { value: "holidayAdjustmentWorkTime", label: "Feiertags Ausgleich" },
      //   { value: "paidHolidayTime", label: "Ausbezahlte Feiertagsarbeit" },
      //   { value: "prevMonthNightWorkTime", label: "Nachtarbeitszeit Vormonat" },
      //   { value: "nightWorkTime", label: "Nachtarbeitszeit" },
      //   { value: "nightWorkCount", label: "Anzahl der Nachtarbeit Schichten" },
      //   { value: "paidNightWorkTime", label: "Ausbezahlte Nachtarbeit" },
      //   { value: "nightWorkTimeBalance", label: "Nachtarbeitszeit Saldo" },
      //   { value: "prevMonthOverTime", label: "Überstunden Vormonat" },
      //   { value: "overTime", label: "Überstunden" },
      //   { value: "overTimeCount", label: "Anzahl der Überstunden Schichten" },
      //   { value: "paidOverTime", label: "Ausbezahlte Überstunden" },
      //   { value: "overTimeBalance", label: "Überstunden Saldo" },
      //
      //   { value: "bonusTime", label: "Bonusstunden" },
      //   { value: "bonusTimeBalance", label: "Bonusstunden Saldo" },
      //   { value: "bonusTimeCount", label: "Bonusstunden Anzahl" },
      //   { value: "paidBonusTime", label: "Ausbezahlte Bonusstunden" },
      //   { value: "prevMonthBonusTime", label: "Bonusstunden Vormonat" },
      //
      //   { value: "moreTime", label: "Mehrstunden" },
      //   { value: "moreTimeBalance", label: "Mehrstunden Saldo" },
      //   { value: "moreTimeCount", label: "Mehrstunden Anzahl" },
      //   { value: "paidMoreTime", label: "Ausbezahlte Mehrstunden" },
      //   { value: "prevMonthMoreTime", label: "Mehrstunden Vormonat" },
      //
      //   { value: "paidWorkTimeOnlyNegative", label: "Ausbezahlte Arbeitszeit (nur -)" },
      //
      //   { value: "paidOverTimeOnlyNegative", label: "Ausbezahlte Überstunden (nur -)" },
      //   { value: "paidMoreTimeOnlyNegative", label: "Ausbezahlte Mehrstunden (nur -)" },
      //   { value: "paidNightWorkTimeOnlyNegative", label: "Ausbezahlte Nachtarbeit (nur -)" },
      //   { value: "paidHolidayTimeOnlyNegative", label: "Ausbezahlte Feiertagsarbeit (nur -)" },
      //   { value: "paidBonusTimeOnlyNegative", label: "Ausbezahlte Bonusstunden (nur -)" },
      //   { value: "workDaysCount", label: "Arbeitstage Ist" },
      //   { value: "overtimeBonus50OnlyPositive", label: "Ausbez. 50% Überst. Zuschlag (nur +)" },
      //   { value: "moretimeBonus25OnlyPositive", label: "Ausbez. 25% Mehrst. Zuschlag (nur +)" },
      // ];

      // fields from enum
      let monthlyFinancialStatementFields = [];
      /*
      view.mdWagetypes.forEach(wageType => {
        monthlyFinancialStatementFields.push({value: wageType.id, label: wageType.name});
      });
      //The backend can not response to all types right now. If one implements more elements, we can add more
      */

      // TODO:
      // Diese Ids (value) werden in der Datenbank (individualwagetypedetails) als enumWageTypeId gespeichert.
      // Das Feld enumWageTypeId ist aber eine Referenz auf enum_wagetypes.id (liste von wagetypes vom monatsabschluss)
      // daher passen die ids nicht zusammen (zB:
      //    id 16 = paidWorkTimeOnlyPositive
      //    id 17 = holidayTime
      //    id 40 = paidWorkTimeOnlyPositive
      //    id 41 = paidWorkTimeOnlyNegative
      //    id 42 = paidOverTimeOnlyPositive
      monthlyFinancialStatementFields.push({value: 16, label: "Ausbez. Urlaub (T) * Durchschnittswerte"});//paidVacationTime
      monthlyFinancialStatementFields.push({value: 17, label: "Ausbez. Urlaub (T)"});//paidVacationTime
      monthlyFinancialStatementFields.push({value: 40, label: "Arbeitszeit mod. * 25% (nur +)"});//paidWorkTime
      monthlyFinancialStatementFields.push({value: 41, label: "(IST - 169h) * 25% (nur +)"});//soll arbeitszeit ist workTimeRegular und produktive Arbeiszeit ist workTime
      monthlyFinancialStatementFields.push({value: 42, label: "(IST - NAZ) * Stundenbonus (nur +)"});//konfigurierbare variablen
      monthlyFinancialStatementFields.sort((a, b) => {
        if (a.label > b.label) {
          return 1;
        } else if (a.label < b.label) {
          return -1;
        }
        return 0;
      });

      let unavailabilityTypesList = JSON.parse(JSON.stringify(mdEmployeeAvailabilityTypes));
      let dynamicOvertimeUnavailabilityTypesList = JSON.parse(JSON.stringify(mdEmployeeAvailabilityTypes));
      if (entity.details && entity.details.dynamicOvertimeUnavailabilityTypes) {
        const selectedTypes = JSON.parse(entity.details.dynamicOvertimeUnavailabilityTypes).map(Number);  // Ensure values are treated as numbers
        dynamicOvertimeUnavailabilityTypesList.forEach(
          (at) => (at.selected = selectedTypes.includes(at.value))
        );
      }

      let isAbsenceType = entity.details && entity.details.workType === 1;
      let unavailabilityWithSubTypesSelected = entity.details && [2,4,8].includes(entity.details.unavailabilityType);
      let showUnavailabilitySubTypes = isAbsenceType && unavailabilityWithSubTypesSelected;
      if (entity.details && entity.details.employeeAvailabilitySubtypes && showUnavailabilitySubTypes) {
        const selectedTypes = JSON.parse(entity.details.employeeAvailabilitySubtypes).map(Number);  // Ensure values are treated as numbers
        mdEmployeeAvailabilitySubTypes.forEach((ast) => {
            ast.selected = selectedTypes.includes(ast.value);
          }
        );
      }

      if (entity.details && entity.details.enabledStores && entity.details.enabledStores != null) {
        const selectedStores = JSON.parse(entity.details.enabledStores).map(Number);  // Ensure values are treated as numbers
        mdStoresEnabled.forEach((es) => {
          es.selected = selectedStores.includes(es.value);
          }
        );
      }


      if (entity.details && entity.details.extraCostTypes) {
        const selectedTypes = JSON.parse(entity.details.extraCostTypes).map(Number);  // Ensure values are treated as numbers
        extraCostTypes.forEach(
          (ect) => (ect.selected = selectedTypes.includes(ect.value))  // Use includes on an array of numbers
        );
      }
      if (entity.details && entity.details.bonusExpenseTypes) {
        const selectedTypes = JSON.parse(entity.details.bonusExpenseTypes).map(Number);  // Ensure values are treated as numbers
        bonusExpenseTypes.forEach(
          (bet) => (bet.selected = selectedTypes.includes(bet.value))  // Use includes on an array of numbers
        );
      }

      if (entity.details) {
        entity.details.useHourlyWage = entity.details.useHourlyWage !== 0 && entity.details.useHourlyWage !== false;
        entity.details.useMaxWorkTime = entity.details.useMaxWorkTime !== 0 && entity.details.useMaxWorkTime !== false;
      }

      let dataEntries = [
        {
          label: "id",
          key: "id",
          hidden: true,
          value: entity.details ? entity.details.id : null,
        },
        {
          label: "wageTypeId",
          key: "wageTypeId",
          hidden: true,
          value: entity.id,
        },
        {
          // this will cause the workType dropdown to not open automatically
          label: "placeholder",
          key: "placeholder",
          hidden: false,
          styles: { 'opacity': '0', 'height': '0px' }
        },


        {
          label: 'Alle Standorte',
          key: 'enabledAllStores',
          hidden: false,
          type: 'bool',
          value: entity.details != null ? entity.details.enabledStores == null : false, // if null or all stores are selected
        },
        {
          label: "Standorte",
          key: "enabledStores",
          hidden: false,
          type: "dropdownsearch",
          styles: { width: "700px" },
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: "",
            items: mdStoresEnabled, // This is a map of all stores
            selectMultiple: true,
            selectedItems: [],
            selectedItem: null,
            onceSelected: function ($event, item, searchbar) {},
            styleLight: true,
            placeholder: "Bitte auswählen...",
          },
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let enabledAllStores = datafill.entries.find((i) => i.key === "enabledAllStores");
            entryBeforeUpdate.hidden = enabledAllStores.value;
            //entryBeforeUpdate.dropdownSearchState.items = view.stores.map(store => ({value: store.id, selected: store.selected, label: `${store.name} (${store.city})`}));
            return entryBeforeUpdate;
          },
          value: entity.details && entity.details.enabledStores
            ? JSON.parse(entity.details.enabledStores)
            : null,
        },
        { divider: true },













        {
          label: "Typ",
          key: "workType",
          hidden: false,
          type: "dropdownsearch",
          styles: { width: "700px" },
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: "",
            items: workTypes,
            selectMultiple: false,
            selectedItems: [],
            selectedItem: entity.details
              ? workTypes.find((wt) => wt.value === entity.details.workType)
              : null,
            onceSelected: function ($event, item, searchbar) {},
            styleLight: true,
            placeholder: "Bitte auswählen...",
          },
          value: entity.details
            ? workTypes.find((wt) => wt.value === entity.details.workType).value
            : null,
        },
        {
          label: "Abwesenheits Typ",
          key: "unavailabilityType",
          hidden: entity.details ? !(entity.details.workType === 1) : false,
          value: entity.details ? entity.details.unavailabilityType : null,
          type: "dropdownsearch",
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: "",
            items: unavailabilityTypesList,
            selectMultiple: false,
            selectedItems: [],
            selectedItem: entity.details ? unavailabilityTypesList.find(ut => ut.value === entity.details.unavailabilityType) : null,
            onceSelected: function ($event, item, searchbar) {},
            styleLight: true,
            placeholder: "Bitte auswählen...",
          },
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let unavailability =
              datafill.entries.find((i) => i.key === "workType").value === 1;

            entryBeforeUpdate.hidden = !unavailability;
            return entryBeforeUpdate;
          },
        },

        {
          label: "Detail: ",
          key: "unavailabilitySubTypes",
          // Default to hidden, will be shown based on computed logic below --> cant do it like this, because it will not show if we open
          // a popup, which is preselected with Abwesenheit Krankheit
          hidden: !showUnavailabilitySubTypes,
          type: "dropdownsearch",
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: "",
            items: entity.details ? mdEmployeeAvailabilitySubTypes.filter(
              (ast) => ast.parentType === entity.details.unavailabilityType
            ) : mdEmployeeAvailabilitySubTypes,
            selectMultiple: true,
            selectedItems: [],
            selectedItem: null,
            onceSelected: function ($event, item, searchbar) {},
            styleLight: true,
            placeholder: "Bitte auswählen...",
          },
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            // Determine if the current workType is 'Abwesenheiten'
            let isAbsenceType = datafill.entries.find((i) => i.key === "workType").value === 1;

            // Determine if 'Krankheit', 'Sonstiges' or 'Lohnfortzahlung' is selected in unavailabilityTypes
            let unavailabilityWithSubTypesSelected = [2,4,8].includes(datafill.entries.find((i) => i.key === "unavailabilityType").value);

            // Show this block if workType is 'Abwesenheiten' and 'Krankheit' is selected
            let showUnavailabilitySubTypes = isAbsenceType && unavailabilityWithSubTypesSelected;
            entryBeforeUpdate.hidden = !showUnavailabilitySubTypes;

            // set items based on parentType
            entryBeforeUpdate.dropdownSearchState.items = mdEmployeeAvailabilitySubTypes.filter(
              (ast) => ast.parentType === datafill.entries.find((i) => i.key === "unavailabilityType").value
            );

            return entryBeforeUpdate;
          },
        },

        {
          label: 'Durchschnittstunden anwenden',
          key: 'useAverages',
          hidden: false,
          type: 'bool',
          value: entity.details != null ? entity.details.useAverageValues : false,
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let worktype = datafill.entries.find((i) => i.key === "workType").value;
            entryBeforeUpdate.hidden = !(worktype === 1);
            return entryBeforeUpdate;
          },
        },
        {
          label: 'Durchschnittlohn anwenden',
          key: 'useAverageWage',
          hidden: false,
          type: 'bool',
          value: entity.details != null ? entity.details.useAverageWage : false,
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let useAverages = datafill.entries.find((i) => i.key === "useAverages");
            entryBeforeUpdate.hidden = !useAverages.value;
            return entryBeforeUpdate;
          },
        },


        {
          label: 'Faktor mit "0" überschreiben',
          key: 'overwritePaymentWithZero',
          hidden: false,
          type: 'bool',
          value: entity.details != null ? entity.details.overwritePaymentWithZero : false, // if null or all stores are selected
        },

        { divider: true },
        {
          label: "Wochentage",
          key: "weekDays",
          hidden: entity.details ? !(entity.details.workType === 0 || entity.details.workType === 1) : false,
          flexGroup: false,
          type: "togglelist",
          toggleEntries: [
            {
              label: "Mo",
              value: 1,
              selected: entity.details ? entity.details.weekDays.includes(1) : true,
            },
            {
              label: "Di",
              value: 2,
              selected: entity.details ? entity.details.weekDays.includes(2) : true,
            },
            {
              label: "Mi",
              value: 3,
              selected: entity.details ? entity.details.weekDays.includes(3) : true,
            },
            {
              label: "Do",
              value: 4,
              selected: entity.details ? entity.details.weekDays.includes(4) : true,
            },
            {
              label: "Fr",
              value: 5,
              selected: entity.details ? entity.details.weekDays.includes(5) : true,
            },
            {
              label: "Sa",
              value: 6,
              selected: entity.details ? entity.details.weekDays.includes(6) : true,
            },
            {
              label: "So",
              value: 7,
              selected: entity.details ? entity.details.weekDays.includes(7) : true,
            },
          ],
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let worktype = datafill.entries.find((i) => i.key === "workType").value;
            entryBeforeUpdate.hidden = !(worktype === 0 || worktype === 1);
            return entryBeforeUpdate;
          },
        },
        {
          label: "Feiertage",
          key: "holidays",
          hidden: entity.details ? !(entity.details.workType === 0 || entity.details.workType === 1) : false,
          type: "dropdownsearch",
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: "",
            items: holidayTypes,
            selectMultiple: false,
            selectedItems: [],
            selectedItem: entity.details
              ? holidayTypes.find((wt) => wt.value === entity.details.holidays)
              : holidayTypes[0],
            onceSelected: function ($event, item, searchbar) {},
            styleLight: true,
            placeholder: "Bitte auswählen...",
          },
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let worktype = datafill.entries.find((i) => i.key === "workType").value;
            entryBeforeUpdate.hidden = !(worktype === 0 || worktype === 1);
            return entryBeforeUpdate;
          },
        },
        {
          label: "Uhrzeit Von",
          key: "timeFrom",
          hidden: entity.details ? !(entity.details.workType === 0 || entity.details.workType === 1) : false,
          type: "timeframe",
          value: entity.details
            ? view.$helpers.getMomentFromStringTZ(entity.details.timeFrom, "HH:mm")
            : this.$helpers.getMomentTZ().hours(0).minutes(0),
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let worktype = datafill.entries.find((i) => i.key === "workType").value;
            entryBeforeUpdate.hidden = !(worktype === 0 || worktype === 1);
            return entryBeforeUpdate;
          },
        },
        {
          label: "Uhrzeit Bis",
          key: "timeTo",
          hidden: entity.details ? !(entity.details.workType === 0 || entity.details.workType === 1) : false,
          type: "timeframe",
          value: entity.details
            ? view.$helpers.getMomentFromStringTZ(entity.details.timeTo, "HH:mm")
            : this.$helpers.getMomentTZ().hours(24).minutes(0),
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let worktype = datafill.entries.find((i) => i.key === "workType").value;
            entryBeforeUpdate.hidden = !(worktype === 0 || worktype === 1);
            return entryBeforeUpdate;
          },
        },
        {
          divider: true,
          hidden: entity.details ? !(entity.details.workType === 0 || entity.details.workType === 1) : false,
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let worktype = datafill.entries.find((i) => i.key === "workType").value;
            entryBeforeUpdate.hidden = !(worktype === 0 || worktype === 1);
            return entryBeforeUpdate;
          },
        },
        {label: 'Min. Arbeitszeit', key: 'minWorkTime', hidden: false, type: 'slider', value: entity.details != null ? entity.details.minWorkTime : 0,
          slider: {hideMin: true, hideMax: true, min: 0, max: 12 *60, value: entity.details != null ? entity.details.minWorkTime : 0, step: 15, DisplayLabel: (val) => {

              return (val/60).toFixed(2)+"h";
            }},
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let worktype = datafill.entries.find((i) => i.key === "workType").value;
            entryBeforeUpdate.hidden = !(worktype === 0 || worktype === 1);
            return entryBeforeUpdate;
          },},
        {label: 'Max. Arbeitszeit anwenden', key: 'useMaxWorkTime', hidden: false, type: 'bool', value: entity.details != null ? entity.details.useMaxWorkTime : false,
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let worktype = datafill.entries.find((i) => i.key === "workType").value;
            entryBeforeUpdate.hidden = !(worktype === 0 || worktype === 1);
            return entryBeforeUpdate;
          },},
        {label: 'Max. Arbeitszeit', key: 'maxWorkTime', hidden: entity.details != null ? !entity.details.useMaxWorkTime : true, type: 'slider', value: entity.details != null ? (entity.details.maxWorkTime != null ?  entity.details.maxWorkTime : 0): 0,
          slider: {hideMin: true, hideMax: true, min: 0, max: 24 *60, value: entity.details != null ? (entity.details.maxWorkTime != null ?  entity.details.maxWorkTime : 0) : 0, step: 15, DisplayLabel: (val) => {

              return (val/60).toFixed(2)+"h";
            }},
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let useMaxWorkTime = datafill.entries.find((i) => i.key == "useMaxWorkTime")
              .value;

            if (useMaxWorkTime) {
              entryBeforeUpdate.hidden = false;
            } else {
              entryBeforeUpdate.slider.value = 0;
              entryBeforeUpdate.value = 0;
              entryBeforeUpdate.slider = Object.assign({}, entryBeforeUpdate.slider);
              entryBeforeUpdate.hidden = true;
            }
            return entryBeforeUpdate;
          }},
        {divider: true,
          hidden: entity.details ? !(entity.details.workType === 0 || entity.details.workType === 1) || (entity.details.workType === 1 && entity.details.useAverages) : false,
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let worktype = datafill.entries.find((i) => i.key === "workType").value;
            let useAverages = datafill.entries.find((i) => i.key === "useAverages");
            entryBeforeUpdate.hidden = !(worktype === 0 || worktype === 1) || (worktype === 1 && useAverages.value);
            return entryBeforeUpdate;
          },},
        {label: 'Bonus anwenden', key: 'useBonus',
          hidden: entity.details ? !(entity.details.workType === 0 || entity.details.workType === 1): false,
          type: 'dropdownsearch', value: entity.details ? entity.details.useBonus : bonusTypes[0].value,
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: '',
            items: bonusTypes,
            selectMultiple: false,
            selectedItem: entity.details ? bonusTypes.find(bt => bt.value === entity.details.useBonus) : bonusTypes[0],
            selectFirst: false,
            styleLight: true,
          },
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let worktype = datafill.entries.find((i) => i.key === "workType").value;
            let useAverages = datafill.entries.find((i) => i.key === "useAverages");
            if(!(worktype === 0 || worktype === 1)  || (worktype === 1 && useAverages.value)){
              entryBeforeUpdate.hidden = true;
              entryBeforeUpdate.value = 0;
              entryBeforeUpdate.dropdownSearchState.selectedItem = bonusTypes[0];
            } else {
              entryBeforeUpdate.hidden = false;
            }
            return entryBeforeUpdate;
          },},
        {label: 'Stundenlohn anwenden', key: 'useHourlyWage',
          hidden: entity.details ? entity.details.useBonus === 0 || (entity.details.bonusMode === 2 || entity.details.bonusMode === 4) : true,
          type: 'bool', value: entity.details != null ? entity.details.useHourlyWage : false,
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let mode = datafill.entries.find(i => i.key == 'bonusMode').value;
            let bonus = datafill.entries.find(i => i.key == 'useBonus').value;
            let worktype = datafill.entries.find((i) => i.key === "workType").value;
            let hiddenWorkTypes = !(worktype === 0 || worktype === 1);
            if(hiddenWorkTypes || bonus == 0 || mode == 2 || mode == 4){
              entryBeforeUpdate.hidden = true;
              entryBeforeUpdate.value = false;
            } else {
              entryBeforeUpdate.hidden = false;
            }

            return entryBeforeUpdate;
          } },
        {label: 'Stundenlohn Bonus', key: 'hourlyWagePercentage',
          hidden: entity.details ? entity.details.useBonus === 0 || (!entity.details.useHourlyWage || entity.details.bonusMode === 2 || entity.details.bonusMode === 4) : true,
          type: 'slider', value: entity.details != null ? entity.details.hourlyWagePercentage : 0,
          slider: {hideMin: true, hideMax: true, min: 0, max: 400, value: entity.details != null ? entity.details.hourlyWagePercentage : 0, step: 1, DisplayLabel: (val) => {

              return val+"%";
            }},
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let useHourlyWage = datafill.entries.find(i => i.key == 'useHourlyWage').value;
            let mode = datafill.entries.find(i => i.key == 'bonusMode').value;
            let bonus = datafill.entries.find(i => i.key == 'useBonus').value;
            let worktype = datafill.entries.find((i) => i.key === "workType").value;
            let hiddenWorkTypes = !(worktype === 0 || worktype === 1);
            if(hiddenWorkTypes || bonus == 0 || !useHourlyWage || mode == 2 || mode == 4){

              entryBeforeUpdate.slider.value = entity.details != null ? entity.details.hourlyWagePercentage : 0;
              entryBeforeUpdate.value = entity.details != null ? entity.details.hourlyWagePercentage : 0;
              entryBeforeUpdate.slider = Object.assign({}, entryBeforeUpdate.slider);
              entryBeforeUpdate.hidden = true;

            } else {
              entryBeforeUpdate.hidden = false;
            }

            return entryBeforeUpdate;
          }
        },
        {label: 'Stunden Bonus', key: 'bonusHour',
          hidden: entity.details ? entity.details.useBonus === 0 || (entity.details.useHourlyWage || entity.details.bonusMode === 2 || entity.details.bonusMode === 4) : true,
          type: 'slider', value: entity.details != null ? entity.details.bonusHour : 0,
          slider: {hideMin: true, hideMax: true, min: 0, max: 100*100, value: entity.details != null ? entity.details.bonusHour : 0, step: 1, DisplayLabel: (val) => {

              return (val/100.0).toFixed(2)+"€";
            }},
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let useHourlyWage = datafill.entries.find(i => i.key == 'useHourlyWage').value;
            let mode = datafill.entries.find(i => i.key == 'bonusMode').value;
            let bonus = datafill.entries.find(i => i.key == 'useBonus').value;
            let worktype = datafill.entries.find((i) => i.key === "workType").value;
            let hiddenWorkTypes = !(worktype === 0 || worktype === 1);
            if(hiddenWorkTypes || bonus == 0 || useHourlyWage || mode == 2 || mode == 4){

              entryBeforeUpdate.slider.value = entity.details != null ? entity.details.bonusHour : 0;
              entryBeforeUpdate.value = entity.details != null ? entity.details.bonusHour : 0;
              entryBeforeUpdate.slider = Object.assign({}, entryBeforeUpdate.slider);
              entryBeforeUpdate.hidden = true;

            } else {
              entryBeforeUpdate.hidden = false;
            }
            return entryBeforeUpdate;
          }
        },
        {label: 'Pauschal Bonus', key: 'bonusShift',
          hidden: entity.details ? entity.details.useBonus === 0 || (entity.details.bonusMode === 3 || entity.details.bonusMode === 5) : true,
          type: 'slider', value: entity.details != null ? entity.details.bonusShift : 0,
          slider: {hideMin: true, hideMax: true, min: 0, max: 100*100, value: entity.details != null ? entity.details.bonusShift : 0, step: 1, DisplayLabel: (val) => {

              return (val/100.0).toFixed(2)+"€";
            }},
          computed: (datafill, lastVal, entryBeforeUpdate) => {

            let mode = datafill.entries.find(i => i.key == 'bonusMode').value;
            let bonus = datafill.entries.find(i => i.key == 'useBonus').value;
            let worktype = datafill.entries.find((i) => i.key === "workType").value;
            let hiddenWorkTypes = !(worktype === 0 || worktype === 1);
            if(hiddenWorkTypes || bonus == 0 || mode == 3 || mode == 5){

              entryBeforeUpdate.slider.value = entity.details != null ? entity.details.bonusShift : 0;
              entryBeforeUpdate.value = entity.details != null ? entity.details.bonusShift : 0;
              entryBeforeUpdate.slider = Object.assign({}, entryBeforeUpdate.slider);
              entryBeforeUpdate.hidden = true;

            } else {
              entryBeforeUpdate.hidden = false;
            }
            return entryBeforeUpdate;
          }
        },
        {label: 'Berechnungs Modus', key: 'bonusMode',
          hidden: entity.details ? entity.details.useBonus === 0 : true, type: 'dropdownsearch',
          value: entity.details ? entity.details.bonusMode : initialMode.value, dropdownSearchState:{
            toggled: false,
            displayedItems: [],
            input: '',
            items: bonusModeItems,
            selectMultiple: false,
            selectedItem: entity.details ? bonusModeItems.find(bm => bm.value === entity.details.bonusMode) : initialMode,
            selectFirst: false,
            styleLight: true,
          },
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let bonus = datafill.entries.find(i => i.key == 'useBonus').value;
            let worktype = datafill.entries.find((i) => i.key === "workType").value;
            let hiddenWorkTypes = !(worktype === 0 || worktype === 1);
            if(hiddenWorkTypes || bonus == 0){
              entryBeforeUpdate.hidden = true;
            } else {
              entryBeforeUpdate.hidden = false;
            }
            return entryBeforeUpdate;
          }
          },
        {label: 'Pauschal Grenze', key: 'bonusCutoff',
          hidden: entity.details ? entity.details.useBonus === 0 || (entity.details.bonusMode === 2 || entity.details.bonusMode === 3) : true,
          type: 'slider', value: entity.details != null ? entity.details.bonusCutoff : 50,
          slider: {hideMin: true, hideMax: true, min: 0, max: 100, value: entity.details != null ? entity.details.bonusCutoff : 50, step: 1, DisplayLabel: (val) => {

              return val+"%";
            }},
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let bonusMode = datafill.entries.find(i => i.key == 'bonusMode').value;
            let bonus = datafill.entries.find(i => i.key == 'useBonus').value;
            let worktype = datafill.entries.find((i) => i.key === "workType").value;
            let hiddenWorkTypes = !(worktype === 0 || worktype === 1);
            if(hiddenWorkTypes || bonus == 0 || bonusMode == 2 || bonusMode == 3){

              entryBeforeUpdate.slider.value = 50;
              entryBeforeUpdate.value = 50;
              entryBeforeUpdate.slider = Object.assign({}, entryBeforeUpdate.slider);
              entryBeforeUpdate.hidden = true;

            } else {
              entryBeforeUpdate.hidden = false;
            }
            return entryBeforeUpdate;
          }
        },



        {
          label: 'Positives Vorzeichen',
          key: 'invertSignWorktype2',
          hidden: entity.details ? !(entity.details.workType === 2) : true,
          type: 'bool',
          value: entity.details != null ? entity.details.invertSign : false,
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let worktype = datafill.entries.find((i) => i.key === "workType").value;
            if(!(worktype === 2)){
              entryBeforeUpdate.hidden = true;
            } else {
              entryBeforeUpdate.hidden = false;
            }
            return entryBeforeUpdate;
          }
        },
        {
          label: 'Negatives Vorzeichen',
          key: 'invertSignWorktype3',
          hidden: entity.details ? !(entity.details.workType === 3) : true,
          type: 'bool',
          value: entity.details != null ? entity.details.invertSign : false,
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let worktype = datafill.entries.find((i) => i.key === "workType").value;
            if(!(worktype === 3)){
              entryBeforeUpdate.hidden = true;
            } else {
              entryBeforeUpdate.hidden = false;
            }
            return entryBeforeUpdate;
          }
        },



        {label: 'Andere Kosten Typ', key: 'extraCostTypes',
          hidden: entity.details ? !(entity.details.workType === 2) : true,
          type: 'dropdownsearch',
          value: entity.details ? (entity.details.extraCostTypes ? JSON.parse(entity.details.extraCostTypes) : []) : extraCostTypes[0].value,
          dropdownSearchState:{
            toggled: false,
            displayedItems: [],
            input: '',
            items: extraCostTypes,
            selectMultiple: true,
            selectedItem: null,
            selectFirst: false,
            styleLight: true,
            placeholder: "Bitte auswählen...",
          },
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let worktype = datafill.entries.find((i) => i.key === "workType").value;
            if(!(worktype === 2)){
              entryBeforeUpdate.hidden = true;
            } else {
              entryBeforeUpdate.hidden = false;
            }
            return entryBeforeUpdate;
          }
        },
        {label: 'Andere Kosten Auszahlung', key: 'extraCostPercentage',
          hidden: entity.details ? !(entity.details.workType === 2) : true,
          type: 'slider', value: entity.details && entity.details.extraCostPercentage ? entity.details.extraCostPercentage : 0,
          slider: {hideMin: true, hideMax: true, min: 0, max: 400, value: entity.details && entity.details.extraCostPercentage ? entity.details.extraCostPercentage : 0, step: 1, DisplayLabel: (val) => {

              return val+"%";
            }},
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let worktype = datafill.entries.find((i) => i.key === "workType").value;
            if(!(worktype === 2)){

              entryBeforeUpdate.slider.value = entity.details && entity.details.extraCostPercentage ? entity.details.extraCostPercentage : 0;
              entryBeforeUpdate.value = entity.details && entity.details.extraCostPercentage ? entity.details.extraCostPercentage : 0;
              entryBeforeUpdate.slider = Object.assign({}, entryBeforeUpdate.slider);
              entryBeforeUpdate.hidden = true;

            } else {
              entryBeforeUpdate.hidden = false;
            }

            return entryBeforeUpdate;
          }
        },
        {label: 'Bonuszahlung Typ', key: 'bonusExpenseTypes',
          hidden: entity.details ? !(entity.details.workType === 3) : true, type: 'dropdownsearch',
          value: entity.details ? entity.details.bonusExpenseTypes : bonusExpenseTypes[0].value, dropdownSearchState:{
            toggled: false,
            displayedItems: [],
            input: '',
            items: bonusExpenseTypes,
            selectMultiple: true,
            selectedItem: null,
            selectFirst: false,
            styleLight: true,
            placeholder: "Bitte auswählen...",
          },
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let worktype = datafill.entries.find((i) => i.key === "workType").value;
            if(!(worktype === 3)){
              entryBeforeUpdate.hidden = true;
            } else {
              entryBeforeUpdate.hidden = false;
            }
            return entryBeforeUpdate;
          }
        },
        {label: 'Lohnart Monatssummen', key: 'enumWageTypeId',
          hidden: entity.details ? !(entity.details.workType === 4) : true, type: 'dropdownsearch',
          value: entity.details && entity.details.enumWageTypeId ? monthlyFinancialStatementFields.find(f => { return f.value === entity.details.enumWageTypeId}).value : null,
          dropdownSearchState:{
            toggled: false,
            displayedItems: [],
            input: '',
            items: monthlyFinancialStatementFields,
            selectMultiple: false,
            selectedItem: entity.details && entity.details.enumWageTypeId != null ?
              monthlyFinancialStatementFields.find(f => { return f.value === entity.details.enumWageTypeId}) : null,
            selectFirst: false,
            styleLight: true,
            placeholder: "Bitte auswählen...",
          },
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let worktype = datafill.entries.find((i) => i.key === "workType").value;
            if(!(worktype === 4)){
              entryBeforeUpdate.hidden = true;
            } else {
              entryBeforeUpdate.hidden = false;
            }
            return entryBeforeUpdate;
          }
        },
        // {
        //   divider: true,
        //   hidden: entity.details ? !(entity.details.workType === 4 && entity.details.enumWageTypeId === 42) : true,
        //   computed: (datafill, lastVal, entryBeforeUpdate) => {
        //     let worktype = datafill.entries.find((i) => i.key === "workType").value;
        //     let enumWageTypeId = datafill.entries.find((i) => i.key === "enumWageTypeId").value;
        //     entryBeforeUpdate.hidden = !(worktype === 4 && enumWageTypeId === 42);
        //     return entryBeforeUpdate;
        //   },
        // },
        {
          label: 'Normalarbeitszeit (NAZ) aus Mitarbeiterverträgen übernehmen',
          key: 'dynamicOvertimeUseContractHours',
          hidden: entity.details ? !(entity.details.workType === 4 && entity.details.enumWageTypeId === 42) : true,
          type: "bool",
          value: entity.details ? entity.details.dynamicOvertimeUseContractHours : false,
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let worktype = datafill.entries.find((i) => i.key === "workType").value;
            let enumWageTypeId = datafill.entries.find((i) => i.key === "enumWageTypeId").value;
            if(!(worktype === 4 && enumWageTypeId === 42)){
              entryBeforeUpdate.hidden = true;
            } else {
              entryBeforeUpdate.hidden = false;
            }

            return entryBeforeUpdate;
          },
        },
        {label: 'Normalarbeitszeit (NAZ)', key: 'dynamicOvertimeWorkTimeRegular',
          hidden: entity.details ? !(entity.details.workType === 4 && entity.details.enumWageTypeId === 42 && !entity.details.dynamicOvertimeUseContractHours) : true,
          type: 'slider', value: entity.details && entity.details.dynamicOvertimeWorkTimeRegular ? entity.details.dynamicOvertimeWorkTimeRegular : 169,
          slider: {hideMin: true, hideMax: true, min: 100, max: 200, value: entity.details && entity.details.dynamicOvertimeWorkTimeRegular ? entity.details.dynamicOvertimeWorkTimeRegular : 169, step: 1, DisplayLabel: (val) => {
              return val+"h";
            }},
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let worktype = datafill.entries.find((i) => i.key === "workType").value;
            let enumWageTypeId = datafill.entries.find((i) => i.key === "enumWageTypeId").value;
            let useContractHours = datafill.entries.find((i) => i.key === "dynamicOvertimeUseContractHours").value;
            if(!(worktype === 4 && enumWageTypeId === 42 && !useContractHours)){

              entryBeforeUpdate.slider.value = entity.details && entity.details.dynamicOvertimeWorkTimeRegular ? entity.details.dynamicOvertimeWorkTimeRegular : 169;
              entryBeforeUpdate.value = entity.details && entity.details.dynamicOvertimeWorkTimeRegular ? entity.details.dynamicOvertimeWorkTimeRegular : 169;
              entryBeforeUpdate.slider = Object.assign({}, entryBeforeUpdate.slider);
              entryBeforeUpdate.hidden = true;

            } else {
              entryBeforeUpdate.hidden = false;
            }

            return entryBeforeUpdate;
          }
        },
        {label: 'Stundenbonus', key: 'dynamicOvertimeBonus',
          hidden: entity.details ? !(entity.details.workType === 4 && entity.details.enumWageTypeId === 42) : true,
          type: 'slider', value: entity.details && entity.details.dynamicOvertimeBonus ? entity.details.dynamicOvertimeBonus : 25,
          slider: {hideMin: true, hideMax: true, min: 0, max: 100, value: entity.details && entity.details.dynamicOvertimeBonus ? entity.details.dynamicOvertimeBonus : 25, step: 1, DisplayLabel: (val) => {
              return val+"%";
            }},
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let worktype = datafill.entries.find((i) => i.key === "workType").value;
            let enumWageTypeId = datafill.entries.find((i) => i.key === "enumWageTypeId").value;
            if(!(worktype === 4 && enumWageTypeId === 42)){

              entryBeforeUpdate.slider.value = entity.details && entity.details.dynamicOvertimeBonus ? entity.details.dynamicOvertimeBonus : 25;
              entryBeforeUpdate.value = entity.details && entity.details.dynamicOvertimeBonus ? entity.details.dynamicOvertimeBonus : 25;
              entryBeforeUpdate.slider = Object.assign({}, entryBeforeUpdate.slider);
              entryBeforeUpdate.hidden = true;

            } else {
              entryBeforeUpdate.hidden = false;
            }

            return entryBeforeUpdate;
          }
        },
        {
          label: 'Produktive Arbeitszeit in IST inkludieren',
          key: 'dynamicOvertimeIncludeProductiveWorktime',
          hidden: entity.details ? !(entity.details.workType === 4 && entity.details.enumWageTypeId === 42) : true,
          type: "bool",
          value: true,
          disabled: true,
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let worktype = datafill.entries.find((i) => i.key === "workType").value;
            let enumWageTypeId = datafill.entries.find((i) => i.key === "enumWageTypeId").value;
            if(!(worktype === 4 && enumWageTypeId === 42)){
              entryBeforeUpdate.hidden = true;
            } else {
              entryBeforeUpdate.hidden = false;
            }

            return entryBeforeUpdate;
          },
        },
        {
          label: 'Abwesenheits Typen in IST inkludieren',
          key: 'dynamicOvertimeUnavailabilityTypes',
          hidden: entity.details ? !(entity.details.workType === 4 && entity.details.enumWageTypeId === 42) : true,
          type: "dropdownsearch",
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: "",
            items: dynamicOvertimeUnavailabilityTypesList,
            selectMultiple: true,
            selectedItems: [],
            selectedItem: null,
            onceSelected: function ($event, item, searchbar) {},
            styleLight: true,
            placeholder: "Bitte auswählen...",
          },
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let worktype = datafill.entries.find((i) => i.key === "workType").value;
            let enumWageTypeId = datafill.entries.find((i) => i.key === "enumWageTypeId").value;
            if(!(worktype === 4 && enumWageTypeId === 42)){
              entryBeforeUpdate.hidden = true;
            } else {
              entryBeforeUpdate.hidden = false;
            }

            return entryBeforeUpdate;
          },
        },
        //{label: 'Sortierungs Priorität', key: 'priority', hidden: false, type: 'slider', value: fibu.sorting, slider: {value: fibu.sorting, min: 1, max: 100}},
        { flexDivider: true, styles: { "margin-bottom": "23px" } },
      ];
      this.$helpers.OpenPopup({
        elRelative: null,
        type: "datafill",
        pointerDirection: 0,
        componentState: {
          entries: dataEntries,
          selectCallback: function (eEntity) {
            view.editDetailsEntity(eEntity);
          },
          errorCheck: {},
        },
        header: "Lohnart Details bearbeiten",
        remainAfterSelect: false,
        centered: true,
        expanded: true,
      });
    },
    openDeleteFinancialAccountingGroup(fibu) {
      let view = this;

      let dataEntries = [
        {
          label: "id: ",
          key: "id",
          hidden: true,
          type: "text",
          value: fibu.id,
          length: 128,
          styles: { width: "350px" },
        },
        {
          label: "",
          key: "numberString",
          hidden: false,
          type: "output",
          value: "Soll das Element wirklich gelöscht werden?",
        },
        {
          label: "Name: ",
          key: "name",
          hidden: false,
          type: "output",
          value: fibu.name,
          length: 128,
          styles: { width: "350px" },
        },
        { flexDivider: true, styles: { "margin-bottom": "23px" } },
      ];
      this.$helpers.OpenPopup({
        elRelative: null,
        type: "datafill",
        pointerDirection: 0,
        componentState: {
          positiveButtonLabel: "Löschen",
          entries: dataEntries,
          selectCallback: function (eFibu) {
            view.deleteFinancialAccountingGroup(eFibu);
          },
        },
        header: "Beschäftigungsmodell löschen",
        remainAfterSelect: false,
        centered: true,
        expanded: true,
      });
    },

    createFinancialAccountingGroup(newFIBU) {
      let view = this;
      let url = "/api/sec/individualWageTypes";
      url = this.$helpers.appendParam(
        "companyId",
        this.$store.state.companyId,
        url,
        "/api/sec/individualWageTypes"
      );
      let payload = {
        companyId: this.$store.state.companyId,
        numberString: newFIBU.numberString,
        name: newFIBU.name,
        priority: 0, //newFIBU.priority,
      };
      this.axios({
        method: "post",
        headers: {
          AUTHORIZATION: "Bearer " + view.$store.state.jwt,
        },
        url: url,
        data: payload,
      })
        .then((response) => {
          view.$helpers.success(
            "Beschäftigungsmodell erstellt",
            "Beschäftigungsmodell erstellt"
          );
          view.refresh(true);
        })
        .catch(function (error) {
          view.$helpers.error("Fehler", "Beschäftigungsmodell konnte nicht erstellt werden");
          console.log(error);
        });
    },
    editEntity(editFIBU) {
      let view = this;
      let url = "/api/sec/individualWageTypes/" + editFIBU.id;
      url = this.$helpers.appendParam(
        "companyId",
        this.$store.state.companyId,
        url,
        "/api/sec/individualWageTypes/" + editFIBU.id
      );
      let payload = {
        companyId: this.$store.state.companyId,
        numberString: editFIBU.numberString,
        name: editFIBU.name,
        priority: 0, //editFIBU.priority,
      };
      this.axios({
        method: "put",
        headers: {
          AUTHORIZATION: "Bearer " + view.$store.state.jwt,
        },
        url: url,
        data: payload,
      })
        .then((response) => {
          view.$helpers.success(
            "Beschäftigungsmodell geändert",
            "Beschäftigungsmodell geändert"
          );
          view.refresh(true);
        })
        .catch(function (error) {
          view.$helpers.error("Fehler", "Beschäftigungsmodell konnte nicht geändert werden");
          console.log(error);
        });
    },
    editDetailsEntity(entity) {
      console.log("entity", entity);
      let view = this;

      let url = "";
      let method = "";
      if (entity.id) {
        url = "/api/sec/individualWageTypeDetails/" + entity.id;
        url = this.$helpers.appendParam(
          "companyId",
          this.$store.state.companyId,
          url,
          "/api/sec/individualWageTypeDetails/" + entity.id
        );
        method = "put";
      } else {
        url = "/api/sec/individualWageTypeDetails";
        url = this.$helpers.appendParam(
          "companyId",
          this.$store.state.companyId,
          url,
          "/api/sec/individualWageTypeDetails"
        );
        method = "post";
      }

      let payload = entity;
      entity.companyId = this.$store.state.companyId;
      entity.timeFrom = entity.timeFrom.format("HH:mm");
      entity.timeTo = entity.timeTo.format("HH:mm");

      this.axios({
        method: method,
        headers: {
          AUTHORIZATION: "Bearer " + view.$store.state.jwt,
        },
        url: url,
        data: payload,
      })
        .then((response) => {
          view.$helpers.success("Lohnart Details geändert", "Lohnart Details geändert");
          view.refresh(true);
        })
        .catch(function (error) {
          view.$helpers.error("Fehler", "Lohnart Details konnten nicht geändert werden");
          console.log(error);
        });
    },

    toggleColorPicker(fibu, val = null) {
      let view = this;
      this.$helpers.OpenColorSelection(
        (color) => {
          fibu.color = color;
          view.saveColor(fibu);
        },
        {
          colorSelected: fibu.color,
          colorBlacklist: this.models.map((s) => {
            return { color: s.color, label: s.name };
          }),
        }
      );
    },
    saveColor(myObj) {
      let view = this;
      let url = "/api/sec/individualWageTypes/" + myObj.id;
      url = this.$helpers.appendParam(
        "companyId",
        this.$store.state.companyId,
        url,
        "/api/sec/individualWageTypes/" + myObj.id
      );
      let payload = {
        companyId: this.$store.state.companyId,
        color: myObj.color,
      };
      this.axios({
        method: "put",
        headers: {
          AUTHORIZATION: "Bearer " + view.$store.state.jwt,
        },
        url: url,
        data: payload,
      })
        .then((response) => {
          view.$helpers.success("Farbe geändert", "Farbe geändert");
          view.refresh(true);
        })
        .catch(function (error) {
          view.$helpers.error("Fehler", "Farbe konnte nicht geändert werden");
          console.log(error);
        });
    },
  },
};
</script>

<!-- Add "scoped" attriemployeete to limit CSS to this component only -->
<style scoped>
.name-filter .fa-search {
  color: var(--contrast-4);
  position: absolute;
  top: 5px;
  left: 5px;
}

.half-width {
  width: 50%;
}

.fix-button-width {
  width: 150px;
}

.filter-input {
  margin-left: -45px;
  padding-left: 40px;
  width: calc(100% - 25px);
}

.list {
  width: calc(100% - 78px);
  margin: auto;
  height: 265px;
  box-shadow: inset 0 0 0 1px var(--contrast-3);
  overflow: hidden;
  position: absolute;
  border-radius: 5px;
}
.list .scroll-content {
  padding: 5px;
  overflow-y: auto;
  height: 265px;
}

.list-entry {
  height: 35px;
  margin-bottom: 2px;
  border-radius: 100px;
  cursor: pointer;
}

.list-entry:hover {
  background: var(--sub-menu);
  cursor: pointer;
}
.list-entry.selected {
  background: #e51550;
  cursor: pointer;
}
.list-entry.selected > .label,
.list-entry:hover > .label {
  color: var(--bg);
  cursor: pointer;
}
.list-entry.selected > .label > .label-small,
.list-entry.hover > .label > .label-small {
  color: var(--bg) !important;
  cursor: pointer;
}

.list-entry > .label {
  float: left;
  font-size: 14px;
  font-family: DistrictProBook;
  color: var(--contrast-4);
  padding-top: 6px;
  cursor: pointer;
}
.list-entry > .label > .label-small {
  cursor: pointer;
  color: var(--contrast-5);
}

.picture {
  float: left;
  border-radius: 100px;
  background: var(--contrast-1);
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 10px;
  height: 25px;
  width: 25px;
}
.position-right {
  float: right;
  margin: 0;
  margin-right: -5px;
}
.pictureImg {
  border-radius: 100px;
  height: 25px;
  width: 25px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--contrast-1);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--contrast-3);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #aaaaaa;
}
.has-header > .has-header > .scrollbar-container {
  height: calc(100% - var(--header-height) - var(--tab-menu-height)) !important;
  overflow-x: hidden;
  top: var(--header-height);
  padding: 0px 14px;
}
</style>
